import cn from 'classnames';
import type { FC } from 'react';

import s from './Switcher.module.scss';

export interface ISwitcherProps {
  isActive: boolean;
  switcherHandler: () => void;
  size?: 's' | 'm';
  isDisabled?: boolean;
  className?: string;
  dataTestId?: string;
}

export const Switcher: FC<ISwitcherProps> = props => {
  const {
    switcherHandler,
    isActive,
    isDisabled,
    className,
    size = 'm',
    dataTestId = 'switcher',
  } = props;

  function handleSwitcher(): void {
    if (isDisabled) {
      return;
    }
    switcherHandler();
  }

  return (
    <div
      data-testid={dataTestId}
      className={cn(className, s.root, {
        [s.disabled]: isDisabled,
        [s.wrapperFalse]: !isActive,
        [s.small]: size === 's',
      })}
      onClick={handleSwitcher}
    />
  );
};
