import cn from 'classnames';
import Image from 'next/image';
import type { ChangeEvent, FC, HTMLAttributes } from 'react';

import s from 'components/UIKit/Checkbox/Checkbox.module.scss';

export interface ICheckbox
  extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
  id: string;
  name: string;
  onChange: (checked: boolean) => void;

  labelText?: string | JSX.Element;
  checked?: boolean;
  isDisabled?: boolean;
  errorMessage?: string | JSX.Element;
  dataTestId?: string;
  isCircle?: boolean;
}

export const Checkbox: FC<ICheckbox> = props => {
  const {
    id,
    name,
    labelText: label,
    className,
    isDisabled,
    onChange: handleChange,
    checked,
    children,
    errorMessage,
    dataTestId,
    isCircle,
  } = props;

  function onChange(e: ChangeEvent<HTMLInputElement>): void {
    handleChange(e.target.checked);
  }

  return (
    <>
      <label
        htmlFor={id}
        className={cn(s.label, className, { disabled: isDisabled })}
        data-testid={dataTestId}
      >
        {checked && !isCircle && (
          <div className={s.image}>
            <Image
              src={
                isDisabled
                  ? '/components/Checkbox/check-gray.svg'
                  : '/components/Checkbox/check.svg'
              }
              width={16}
              height={16}
              className={s.borderRadiusForDisabled}
              alt=""
            />
          </div>
        )}

        {checked && isCircle && <div className={s.circle} />}

        <input
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          className={cn(s.checkbox, {
            [s.errorBorder]: errorMessage,
            [s.circleInput]: isCircle,
          })}
          disabled={isDisabled}
        />

        <span
          className={cn(s.labelText, { [s.textColorForDisabled]: isDisabled })}
        >
          {children ? children : label}
        </span>
      </label>
      {errorMessage && (
        <span className={cn(s.errorMessage)}>{errorMessage}</span>
      )}
    </>
  );
};
