import cn from 'classnames';
import { ChangeEvent, FC, SyntheticEvent, useState } from 'react';
import MaskInput from 'react-maskinput';

import { Icon } from '../Icon';

import s from './InputText.module.scss';

interface IInputTextProps {
  labelText?: string;
  value?: string;
  onChange?: (value: string) => void;
  name?: string;
  className?: string;
  errorMessage?: string;
  type?:
    | 'text'
    | 'textarea'
    | 'password'
    | 'number'
    | 'card'
    | 'passport'
    | 'snils'
    | 'phone'
    | 'passportCode';
  debouncedValidate?: () => void;
  autocomplete?: string;
  placeholder?: string;
  helpText?: string;
  withLengthCounter?: boolean;
  maxLength?: number;
  isDisabled?: boolean;
  resizable?: boolean;
  hasError?: boolean;
  onBlur?: (value: string) => void;
  onClick?: () => void;
  dataTestId?: string;
}

export const InputText: FC<IInputTextProps> = props => {
  const {
    labelText,
    value,
    onChange: onChangeProp,
    name,
    errorMessage,
    helpText,
    type = 'text',
    debouncedValidate,
    autocomplete,
    placeholder,
    maxLength = 0,
    withLengthCounter,
    isDisabled,
    className,
    resizable = false,
    onBlur: onBlurProp,
    hasError = false,
    onClick,
    dataTestId,
  } = props;

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const onChange = (e: SyntheticEvent): void => {
    // Грязный хак, чтобы принудить тайпскрипт не говнить на событие
    const target = e.target as HTMLInputElement;

    if (onChangeProp) {
      onChangeProp(target.value);
    }

    if (debouncedValidate && target.value !== '') {
      debouncedValidate();
    }
  };

  const onBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onBlurProp) {
      onBlurProp(e.target.value);
    }
  };

  const toggleShowPassword = (): void => {
    setIsPasswordShown(!isPasswordShown);
  };

  const hasErrorMessage =
    hasError || (errorMessage !== undefined && errorMessage.length > 0);
  const hasPlaceholder = placeholder !== undefined && placeholder.length > 0;

  let input = <div />;

  let valueLength = 0;

  if (value) {
    valueLength = value.toString().length;
  }
  if (type === 'text' || type === 'number') {
    input = (
      <input
        onClick={onClick}
        id={name}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
        onBlur={(e): void => {
          if (debouncedValidate) {
            debouncedValidate();
          }
          onBlur(e);
        }}
        type={type}
        autoComplete={autocomplete}
        placeholder={placeholder}
        className={cn(s.input, {
          [s.withErrorBorder]: hasErrorMessage,
          [s.noLabel]: !labelText,
        })}
        data-testid={dataTestId ?? 'text-input'}
      />
    );
  }

  if (type === 'password') {
    input = (
      <input
        id={name}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
        type={isPasswordShown ? 'text' : 'password'}
        autoComplete={autocomplete}
        className={cn(s.input, {
          [s.withErrorBorder]: hasErrorMessage,
          [s.noLabel]: !labelText,
        })}
        placeholder={placeholder}
        data-testid={dataTestId ?? 'password-input'}
      />
    );
  }

  if (type === 'textarea') {
    input = (
      <textarea
        id={name}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={(): void => {
          if (debouncedValidate) {
            debouncedValidate();
          }
        }}
        autoComplete={autocomplete}
        className={cn(
          s.input,
          s.textAriaSize,
          {
            [s.withErrorBorder]: hasErrorMessage,
            [s.resizable]: resizable,
            [s.noLabelTextarea]: !labelText,
          },
          className,
        )}
        data-testid={dataTestId ?? 'textarea-input'}
      />
    );
  }

  if (type === 'card') {
    input = (
      <MaskInput
        onChange={onChange}
        onBlur={(): void => {
          if (debouncedValidate) {
            debouncedValidate();
          }
        }}
        maskChar=" "
        mask="0000 0000 0000 0000"
        // @ts-ignore
        className={cn(s.input, {
          [s.withErrorBorder]: hasErrorMessage,
          [s.noLabel]: !labelText,
        })}
        placeholder="0000 0000 0000 0000"
        disabled={isDisabled}
      />
    );
  }

  if (type === 'passport') {
    input = (
      <MaskInput
        onChange={onChange}
        onBlur={(): void => {
          if (debouncedValidate) {
            debouncedValidate();
          }
        }}
        maskChar=" "
        mask="0000 000000"
        // @ts-ignore
        className={cn(s.input, {
          [s.withErrorBorder]: hasErrorMessage,
          [s.noLabel]: !labelText,
        })}
        placeholder="0000 000000"
      />
    );
  }

  if (type === 'snils') {
    input = (
      <MaskInput
        onChange={onChange}
        onBlur={(): void => {
          if (debouncedValidate) {
            debouncedValidate();
          }
        }}
        maskChar=" "
        mask="000-000-000 00"
        // @ts-ignore
        className={cn(s.input, {
          [s.withErrorBorder]: hasErrorMessage,
          [s.noLabel]: !labelText,
        })}
        placeholder="000-000-000 00"
      />
    );
  }

  if (type === 'passportCode') {
    input = (
      <MaskInput
        onChange={onChange}
        onBlur={(): void => {
          if (debouncedValidate) {
            debouncedValidate();
          }
        }}
        maskChar=" "
        mask="000-000"
        // @ts-ignore
        className={cn(s.input, {
          [s.withErrorBorder]: hasErrorMessage,
          [s.noLabel]: !labelText,
        })}
        placeholder="000-000"
      />
    );
  }

  if (type === 'phone') {
    input = (
      <MaskInput
        onChange={onChange}
        onBlur={(): void => {
          if (debouncedValidate) {
            debouncedValidate();
          }
        }}
        maskChar=" "
        mask="+7 000 000 00 00"
        // @ts-ignore
        className={cn(s.input, {
          [s.withErrorBorder]: hasErrorMessage,
          [s.noLabel]: !labelText,
        })}
      />
    );
  }

  return (
    <label
      htmlFor={name}
      className={cn(
        s.label,
        {
          [s.labelWithError]: errorMessage,
        },
        className,
      )}
      data-testid={dataTestId}
    >
      {input}
      {labelText && (
        <div
          className={cn(s.floatLabel, {
            [s.backLabelColor]: type === 'textarea',
            [s.withError]: hasErrorMessage,
            [s.activeLabel]: hasPlaceholder,
            [s.truValue]: !!value,
          })}
        >
          {labelText}
        </div>
      )}
      <div className={s.wrapperInfo}>
        <div
          className={cn(s.helpText, {
            [s.withError]: hasErrorMessage,
          })}
        >
          {errorMessage ? errorMessage : helpText}
        </div>

        {withLengthCounter && (
          <div
            className={cn(s.lengthCounter, {
              [s.withError]: hasErrorMessage,
            })}
          >
            {valueLength}/{maxLength}
          </div>
        )}
      </div>
      {type === 'password' && (
        <span
          className={s.showPasswordWrapper}
          onClick={toggleShowPassword}
          aria-roledescription="button"
        >
          {!isPasswordShown ? (
            <Icon icon="eyeClosed" size={24} />
          ) : (
            <Icon icon="eyeOpen" size={24} />
          )}
        </span>
      )}
    </label>
  );
};
